.arabicWrapper {
    direction: rtl;

    * {
        font-family: 'Cairo', sans-serif !important;
        letter-spacing: 0px !important;
    }
    .staticWhatsapp{
        left:70px;
        right: unset;
    }
    .input-field::-webkit-input-placeholder,
    .text-field::-webkit-input-placeholder {
        font-family: 'Cairo', sans-serif !important;

    }

    .team .swiper-button-next,
    .team .swiper-button-prev {
        transform: rotate(180deg);
    }

    .homeWrapper .firstSlideBtn svg {
        transform: rotate(180deg);
        margin-inline: 0;
        margin-inline-start: 10px;
    }

    // .aboutUs-Ellipse{
    //     left:0;
    // }
    .allProjects nav .filterSwiper {
        height: 57px;
    }

    .ellipseBg3 {
        right: unset;
        left: -300px;
    }

    .ellipseBg2 {
        right: unset;
        left: -170px;
    }

    .growSection .defaultBtn svg {
        transform: rotate(180deg);
    }

    p,
    .homeWord {
        line-height: 29px;
        // font-size: 1em;
    }
    .cardWord p{
     font-size: 1em;

    }

    .seconslideHeade,
    .seconslideHeade p {
        font-size: 4.5rem;
    }

    .homeHeadline {
        font-size: 4em;
    }

    .cardWord span {
        font-size: 1.8em;
    }

    .clientname {
        font-size: 1em;
    }

    .clientpos {
        font-size: 0.8em;
    }

    .servicesHeadline {
        font-size: 3.5em;
        line-height: 59px;
    }

    .homeLink svg {
        transform: rotate(180deg);
    }

    @media (min-width:1200px) and (max-width:1400px) {

        .seconslideHeade,
        .seconslideHeade p {
            font-size: 3.5rem;
        }

        .carsouleItem {
            padding-top: 0 !important;
        }

        .firstSlide {
            padding-bottom: 0 !important;
        }

        .thirdSildebtn {
            margin-bottom: 0;
        }

        .seconslideHeade,
        .seconslideHeade p {
            line-height: 70px !important;
        }
    }

    @media screen and (max-width:1024px) {

        .seconslideHeade,
        .seconslideHeade p {
            font-size: 3rem;
        }
    }

    @media screen and (max-width:992px) {

        .seconslideHeade,
        .seconslideHeade p {
            font-size: 2.5rem;
        }
    }
}