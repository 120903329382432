.home {
  min-height: calc(100vh - 160px);
  box-sizing: border-box;
}
.homeSlider > .swiper-wrapper:first-of-type{
  min-height: calc(100vh - 160px);
}
.home .swiper-slide {
  height: auto !important;
}
.carsouleItem,
.firstSlide,
.secondSlide,
.thirdSilde,
.fourthSlide,
.lastSlide {
  height: 100%;
}

.carsouleItem {
  column-gap: 50px;
  padding: 20px;
  padding-top: 100px !important;
  height: 100%;
  /* margin-top: 50%;
    transform:translateY(-50%) */
}

.firstSlide {
  padding-bottom: 107px;
}

.secondSlide {
  padding-bottom: 40px;
}

.video {
  width: 750px;
  height: 35.063em;
}

.videoIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.videoWrapper {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  height: 35.063em;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.videoWrapper .react-player__preview {
  border-radius: 5px;
}

.homeHeadline {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 5em;
  line-height: 99px !important;
  color: var(--mainText);
}

.homeWord {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.563em;
  line-height: 30px;
  color: var(--grey);
}

.defaultBtn {
  background: var(--yellow);
  border-radius: 5px;
  padding: 13px 30px;
  border: none;
  color: var(--background);
  width: fit-content;
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.563em;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultBtn svg {
  margin-inline-start: 10px;
}

.defaultBtn svg path {
  fill: #fff;
}

.defaultBtn:hover,
.defaultBtn svg path {
  color: #000;
}

.defaultBtn:hover svg path {
  fill: #000;
}

.firstSlideBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.cardsSwiper {
  width: 100%;
  height: 25em;
  padding: unset !important;
  padding-block: 20px !important;
}

.cardsSwiper .cards {
  margin-inline: 15px;
}

/* .cardsSwiper .swiper-button-next {
  background-image: url("../../images/rightArrow.svg");
}

.cardsSwiper .swiper-button-prev {
  background-image: url("../../images/leftArrow.svg");
}

.cardsSwiper .swiper-button-next,
.cardsSwiper .swiper-button-prev {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
} */

.cardsSwiper .swiper-button-next::after,
.cardsSwiper .swiper-button-prev::after,
.cardsSwiper .swiper-button-next,
.cardsSwiper .swiper-button-prev {
  display: none;
}

.seconslideHeade,
.seconslideHeade p {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 4.125rem;
  line-height: 82px !important;
  color: var(--mainText);
  margin-bottom: 30px;
  text-align: start;
}

.homeCard {
  border: 3px solid #a58c3b;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1.25em;
  z-index: 2;
  background-color: var(--background);
}

.homeCard img {
  /* width:40px; */
  height: 50px;
}

.cardWord {
  margin-inline-start: 10px;

}

.cardWord span {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.188em;
  line-height: 43px !important;
  color: var(--mainText);
}

.cardWord p,
.cardWord div {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25em;
  line-height: 24px;
  color: var(--grey);
}

.cardWord div p:last-of-type {
  display: inline !important;
}


.ellipseBg {
  position: absolute;
  bottom: -108px;
  right: 0;
  z-index: -1;
}

.thirdSildebtn {
  width: 272px;
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
  margin-inline-start: 15px;
}

.ellipseBg2 {
  position: absolute;
  top: -90px;
  right: -170px;
  z-index: -1;
}

.ellipseBg3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -359px;
  z-index: -1;
}

.fourthSlide {
  padding: 35px 20px;
}

.fourthSlide .clinetsHeader {
  padding-inline-start: 8.563rem;
}

/* .clinetsHeader {
  padding-inline-start: 100px;
  width: fit-content;
} */
.clientsToprow {
  column-gap: 20px;
}

.clinetReviews {
  width: 50%;
}

.cards {
  box-shadow: 0px 1px 23px rgb(0 0 0 / 10%);
  border-radius: 5px;
  height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clientCard {
  background: var(--background);
  border: 3px solid #a58c3b;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.625em;
}

.clientImg {
  border-radius: 50%;
  width: 4.375em;
  height: 4.375em;
  object-fit: cover;
  margin-inline-end: 10px;
}

.clientname {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 25px;
  color: #7c7b7b;
}

.clientpos {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  color: #7c7b7b;
}

.clientCard .clientData {
  border-bottom: 2px solid #282828;
}

.clientPar {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #7c7b7b;
  margin-top: 20px;
  padding: 0;
  margin-bottom: 0;
}

.fifthslidePar {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.563em;
  line-height: 30px;
  color: #7c7b7b;
  text-align: start;
}

.lastSlide {
  padding-top: 80px;
  padding-bottom: 80px;
}

.photo-list {
  width: 100%;
  list-style: none;
  margin: 20px auto;
}

.photo-item {
  display: flex;
  width: 300px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  margin: 20px 0;
  border-radius: 50%;
}


.photo-item img {
  display: flex;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.reviewsSliderWrapper .swiper-slide {
  height: auto !important;

}

.reviewsSlider {
  min-height: 400px;
  width: 90%;
}

.reviewsSlider .swiper-pagination {
  bottom: 10px;
}

.reviewsSlider .swiper-pagination-bullet-active {
  background-color: var(--yellow);
}

.circular-projects {
  height: 100%;
}

.mobileEllipse {
  width: inherit;
}

.circular-projects {
  position: relative;
  width: 38em;
  height: 38em;
  padding: 2.8em;
  border-radius: 50%;
  margin-inline-end: 1em;
}

.circular-projects .project {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -2em;
}

.circular-projects .project img {
  position: absolute;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  display: block;
  width: 100%;
  height: 100%;
}

.circular-projects .project:hover img {
  transform: scale(1.1);
  transition: all 0.5s ease;
}

.circular-projects .project:nth-child(1) {
  transform: translate(11em);
  width: 13.75em;
  height: 13.75em;
}

.circular-projects .project:nth-child(2) {
  transform: rotate(45deg) translate(-4em) rotate(-45deg);
  width: 11.438em;
  height: 11.438em;
}

.circular-projects .project:nth-child(3) {
  transform: rotate(116deg) translate(12em) rotate(-116deg);
  width: 12.5em;
  height: 12.5em;
}

.circular-projects .project:nth-child(4) {
  transform: translate(-18em);
  width: 12.5em;
  height: 12.5em;
}

.circular-projects .project:nth-child(5) {
  transform: rotate(225deg) translate(24em) rotate(-225deg);
  width: 15.625em;
  height: 15.625em;
}

.circular-projects .project:nth-child(6) {
  transform: rotate(279deg) translate(15em) rotate(-279deg);
  width: 11.438em;
  height: 11.438em;
  left: 56%;
}

.projectswiper .swiper-custom-button-next,
.projectswiper .swiper-custom-button-prev {
  display: none;
}

.testimonialsSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.customCol5 {
  width: 39.666667% !important;
}

.thirdTestimonial {
  display: flex;
}
.servicesSection{    
  margin-top: 3rem !important;
}
@media(max-width: 1600px) {
  .thirdTestimonial {
    display: none !important;
  }
}

@media(max-width: 1500px) {
  .servicesSection{    
    margin-top: 1.8rem !important;
  }
  .homeCard{
    padding: 1.1em;
  }
  .circular-projects {
    width: 30em;
    height: 30em;
    font-size: 12px;
  }

  .circular-projects .project:nth-child(1) {
    transform: translate(8em);
  }

  .circular-projects .project:nth-child(2) {
    transform: rotate(45deg) translate(-7em) rotate(-45deg);
  }

  .circular-projects .project:nth-child(3) {
    transform: rotate(120deg) translate(10em) rotate(-120deg);
  }

  .circular-projects .project:nth-child(4) {
    transform: translate(-19em);
  }

  .circular-projects .project:nth-child(5) {
    transform: rotate(225deg) translate(28em) rotate(-225deg);
    left: 56%;
  }

  .circular-projects .project:nth-child(6) {
    transform: rotate(279deg) translate(16em) rotate(-279deg);
  }

  .cardWord span {
    font-size: 1.8em;
  }

  .cardWord p {
    font-size: 1em;
  }

  .seconslideHeade,
  .seconslideHeade p {
    font-size: 3.125rem;
  }

  .homeHeadline {
    font-size: 3.5em;
  }
}

@media (max-width: 1400px) {
  .clientCard {
    height: 100%;
  }

  .secondSlide .defaultBtn {
    width: 100%;
  }
}
@media (max-width: 1366px) {
  .serviceHidden{
    display: none;
  }
}

@media (max-width: 1200px) {
  .firstSlide {
    padding-bottom: 0;
}
  .fourthSlide .clinetsHeader {
    padding-inline-start: 5.563rem;
  }

  .seconslideHeade,
  .seconslideHeade p {
    font-size: 3.125rem;
    margin-bottom: 1rem;
  }
  .servicesNavtabsLarge {
      width: 300px !important;
      height: 88px;
  }
}

@media (max-width: 1150px) {
  .lastSlide {
    padding-top: 199px;
  }
}

@media (max-width: 1024px) {
  /* .home {
    height: 100vh;
    overflow-y: scroll !important;
  } */

  .home .splitter {
    margin: 45px 0 64px;
  }

  .homeHeadline {
    font-size: 36px;
    line-height: 50px !important;
  }

  .homeWord {
    font-size: 20px;
    line-height: 24px;
  }

  .videoWrapper {
    width: 100%;
    height: 328px;

  }

  .videoIcon {
    width: 65px;
    height: 52px;
  }

  .defaultBtn,
  .defaultBtn * {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .seconslideHeade,
  .seconslideHeade p {
    font-size: 35px;
    line-height: 75px !important;
    padding-inline-start: 2px;
  }

  .clientCard {
    min-height: 303px;
  }

  .clientImg {
    width: 63px;
    height: 63px;
  }

  .clientname {
    font-size: 18px;
    line-height: 22px;
  }

  .clientpos,
  .clientPar {
    font-size: 13px;
    line-height: 17px;
  }

  .lastSlide {
    padding: 0;
  }

  .circular-projects {
    visibility: visible !important;
    margin: 50px 0 100px;
    font-size: 10px;
    border: 20px solid rgba(165, 140, 59, 0.25);
    width: 30em !important;
  }

  .circular-projects .project {
    position: absolute;
    border-radius: 50%;
    object-fit: contain;
  }

  .circular-projects .project:nth-child(1) {
    transform: translate(8em);
    width: 11.75em;
    height: 11.75em;
  }

  .circular-projects .project:nth-child(2) {
    transform: rotate(45deg) translate(-4em) rotate(-45deg);
    width: 9.438em;
    height: 9.438em;
  }

  .circular-projects .project:nth-child(3) {
    transform: rotate(116deg) translate(10em) rotate(-116deg);
    width: 10.5em;
    height: 10.5em;
    left: 53%;
  }

  .circular-projects .project:nth-child(4) {
    transform: translate(-15em);
    width: 10.5em;
    height: 10.5em;
    top: 54%;
  }

  .circular-projects .project:nth-child(5) {
    transform: rotate(225deg) translate(22em) rotate(-225deg);
    width: 13.625em;
    height: 13.625em;
  }

  .circular-projects .project:nth-child(6) {
    transform: rotate(279deg) translate(13em) rotate(-279deg);
    width: 9.438em;
    height: 9.438em;
    left: 56%;
  }

  .swiper .servicesNavtabsLarge {
    height: 75px;
  }

  .firstSlideBtn {
    margin-top: 76px;
  }

  .cardsSwiper {
    width: 70%;
    height: unset;
  }

  .cardsSwiper .cards {
    height: 150px;
  }

  /* .projectswiper {
    margin-top: 100px;
  } */

  .projectswiper .swiper-custom-button-next,
  .projectswiper .swiper-custom-button-prev {
    display: block;
  }

  .projectswiper .swiper-custom-button-next {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 33px;
    /* width: 45px;
    height: 45px; */
    transform: translateY(-50%);
  }

  .projectswiper .swiper-custom-button-prev {
    position: absolute;
    top: 50%;
    left: 33px;
    /* width: 45px;
    height: 45px; */
    transform: translateY(-50%);
    margin-top: 0px;

  }

  .thirdSildebtn {
    margin-top: 80px;
  }

  .firstSlide {
    padding-bottom: 10px;
  }
}

@media (max-width: 1200px) {
  .carsouleItem {
    /* padding-top: 30px !important; */
    width: 76% !important;
    margin: auto;
  }

  .defaultBtn,
  .defaultBtn * {
    font-size: 16px;
  }
}

@media (max-width: 750px) {
  .cardsSwiper .cards {
    height: 242px;
  }
}

@media (max-width: 600px) {
  .carsouleItem {
    width: 100% !important;
    padding-top: 30px !important;
  }

  .circular-projects {
    font-size: 9px;
    margin-bottom: 125px !important;
  }
  .lastSlide {
    overflow: hidden;
  }
}

@media (max-width: 450px) {
  .cardsSwiper {
    width: 90%;
  }

  .projectswiper .swiper-custom-button-prev {
    left: 3px;
  }

  .projectswiper .swiper-custom-button-next {
    right: 2px;
  }
}