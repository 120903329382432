.footerWrapper {
  border-top: 2px solid #282828;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  background-color: var(--background);
}
.socialIcons img{
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.socialIcons:not(:last-child) {
  padding-inline-end: 3.625rem;
}
.langToggler{
  cursor: pointer;
}
.langToggler .flagImg{
  width: 30px;
  height: 20px;
  border-radius: 5px;
}
/* switch dark,light mode toggler */
.switch {
  position: relative;
  display: inline-block;
  width: 57px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: -3px;
  bottom: 0;
  background-color: #e4e4e4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
/* Rounded sliders */
.slider.round {
  border-radius: 59px;
}

.slider.round:before {
  border-radius: 59px;
}
.themeToggler:checked + .slider {
  background-color: #3F3F3F;
}
.themeToggler:focus + .slider {
  background-color: 0 0 1px #3F3F3F;
}
.themeToggler:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider:before {
  position: absolute;
  content: "";
  background-image: url("../../images/moon.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.themeToggler:checked + .slider:before {
  background-image: url("../../images/sun.svg");
}
.footerFont {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #7c7b7b;
}
@media  (max-width:760px) {
  .footerWrapper {
    justify-content:space-between;
    align-items:center
}
.socialIcons img {
  width: 27px;
  height: 27px;
}
.socialIcons:not(:last-child) {
  padding-inline-end: 2.625rem;
}
}
@media  (max-width:550px) {
  .footerWrapper {
    /* flex-direction: column; */
    height :200px;
    justify-content:center;
    align-items:center;
}
}
@media (min-width:992px) and (max-width:1200px){
  .socialIcons {
    padding-inline-end: 20px !important;
  }
}
