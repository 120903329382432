.contactHeade {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 69px;
  color: var(--mainText);
}

.addressContainer {
  border: 3px solid var(--yellow);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 50px 50px;
  box-sizing: border-box;
  margin-top: 79px;
}

.contactUs {
  min-height: calc(100vh - 160px);
  padding: 50px 0;
  box-sizing: border-box;
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #282828;
  height: 108px;
}

.tabs .nav-tabs {
  border: none !important;
  text-align: center;
}

.tabs .nav-tabs li {
  text-align: center;
}

.contactTabs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  font-family: " Kumbh Sans", sans-serif;
  background: transparent !important;
}

.tabs .nav-tabs .navlinks span {
  font-size: 21.5559px;
  line-height: 27px;
  text-align: center;
  color: var(--grey);
  background: transparent !important;
}

.tabs .nav-tabs .navlinks.active span {
  border: none;
  font-style: normal;
  font-weight: normal;
  color: var(--mainText) !important;
  border-bottom: 3px solid var(--yellow);
  padding: 5px;
}

.tabs .nav-tabs .navlinks:hover span {
  border: none;
  border-bottom: 3px solid var(--yellow);
}

#Uae-tab {
  border-inline-end: 3px solid var(--yellow);
}
/* .formSection{
  align-self: flex-start;
} */
.addressContent {
  margin-top: 30px;
  margin-bottom: 50px;
}
.addressContent svg{
  cursor: pointer;
}
.adressHead {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21.5559px;
  line-height: 27px;
  color: var(--mainText);
}

.adressPar {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: var(--grey);
  text-decoration: none;
}
.adressPar:hover{
  color: var(--yellow);
}

.input-field {
  height: 53px;
  background: transparent;
  border: 2.58671px solid #a58c3b;
  box-sizing: border-box;
  border-radius: 4.31118px;
  width: 100%;
  margin-top: 43px;
  color: var(--mainText);
  padding-inline-start: 15px;
  background-color: var(--background);
}

.input-field:focus ,.text-field:focus {
  box-shadow: inset 0px 3.44895px 8.62237px rgba(0, 0, 0, 0.25);
  outline: none;
}

.text-field {
  border: 2.58671px solid #a58c3b;
  box-sizing: border-box;
  border-radius: 4.31118px;
  width: 100%;
  height: 100px;
  max-height: 100px;
  margin-top: 43px;
  background: transparent;
  color: var(--mainText);
  background-color: var(--background);
  padding: 15px;
}

.input-field::-webkit-input-placeholder,
.text-field::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17.2447px;
  line-height: 21px;
  color: var(--grey);
  /* padding: 10px; */
}

.input-field::-moz-placeholder,
.text-field::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17.2447px;
  line-height: 21px;
  /* identical to box height */


  color: var(--grey);
  padding: 10px;
}

.input-field:-ms-input-placeholder,
.text-field:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17.2447px;
  line-height: 21px;
  color: var(--grey);
  padding: 10px;
}

.input-field:-moz-placeholder,
.text-field:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Kumbh Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17.2447px;
  line-height: 21px;
  color: var(--grey);
  padding: 10px;
}

.errorText {
  color: var(--mainText);
  margin-top: 5px;
}

.contactBtn {
  margin-top: 43px;
}
.contactBtn button{
  width:152px;
}
.contactEllipse{
  bottom: -88px;
  position: absolute;
  z-index: -10;
  display: none;
}
@media (max-width:1400px) {
  .contactUs .addresSection {
    margin-bottom: 70px;
  }

  .contactHeade {
    font-size: 35px;
    line-height: 40px;
  }

  .tabs .nav-tabs .navlinks span,
  .adressPar {
    font-size: 17px;
  }

  .addressContainer {
    padding: 10px 20px;
  }

  .adressHead {
    font-size: 15px;
  }

  .addressContent {
    margin-bottom: 10px;
  }

  .addressIcon svg {
    height: 30px;
  }

  .input-field {
    height: 45px;
  }

  .text-field {
    height: 115px;
  }
}

@media (max-width:1024px) {
  .contactUs .addresSection {
    margin-bottom: 80px;
  }

  .contactHeade {
    font-size: 40px;
    line-height: 50px;
  }

  .tabs .nav-tabs .navlinks span,
  .adressPar {
    font-size: 15px;
  }

  .addressContainer {
    padding: 20px;
  }

  .adressHead {
    font-size: 16px;
  }

  .addressContent {
    margin-bottom: 10px;
  }

  .addressIcon svg {
    height: 30px;
  }

  .text-field {
    height: 106px;
  }
}
@media screen and (max-width:992px) {
  .contactEllipse{
    display: block;
  }
}