.pagesWrapper{
    padding-bottom: 50px;
}
.offerCard {
    border: 1px solid rgba(203, 174, 79, 0.3);
    // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 6px;
    padding: 2.5em 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .packageImg {
        width: 8em;
        height: 8em;
    }

    .packageName {
        // width: 100%;
        // text-align: center;
        // border-top: 1px solid rgba(203, 174, 79, 0.3);
        // line-height: 0.1em;
        margin-top: 4.125em;
        margin-bottom: 1.875em;
        display: flex;
        align-items: center;
        width: 100%;

        span {
            display: inline-block;
        }

        .line {
            height: 1px;
            background-color: rgba(203, 174, 79, 0.3);
            flex-grow: 1;
        }

        .name {
            font-size: 2.125em;
            color: var(--mainText);
            background-color: var(--background);
            padding: 0 1.25em;
            color: var(--yellow);
            text-align: center;
        }
    }

    .offerData {
        padding: 0 1.938em;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
            font-weight: 400;
            font-size: 0.938em;
            line-height: 25px;
            text-align: center;
            color: var(--grey);
            margin-bottom: 2.3em;
        }
    }

    .offerPoint {
        display: flex;
        align-items: center;

        img {
            width: 1.25em;
            height: 0.813em;
            margin-inline-end: 1.125em;
        }

        &:not(:last-child) {
            margin-bottom: 2.063em;
        }

        span {
            color: var(--grey);
            font-size: 0.938em;
        }
    }

    .offerBtn {
        font-size: 0.938em;
        margin-top: 4.5em;
        width: 100%;
    }
}

// .offersSwiper {
//     .swiper-wrapper {
//         vertical-align: middle;
//         min-height: 1000px;
//         padding-top: 67px;
//     }

//     .swiper-slide-active {
//         .offerCard {
//             vertical-align: middle;
//             transition-duration: 0.3s;
//             transition-property: transform;
//             transition-timing-function: ease-out;
//             transform: translateY(-64px);
//         }
//     }
// }

@media screen and (max-width:1600px) {
    .offerCard .packageName .name {
        font-size: 1.5em;
    }
}
@media screen and (max-width:1200px) {
    // .offersSwiper {
    //     .swiper-slide-active {
    //         .offerCard {

    //             transform: translateY(0);
    //         }
    //     }
    // }
    .offerItem {
        margin-bottom: 50px;
    }

}
@media screen and (max-width:992px){
    .offerCard .packageName {
        margin-top: 2.125em;
    }
}