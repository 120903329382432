@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&family=Kumbh+Sans:wght@300;400;500&family=Roboto:wght@300;400;500;700&family=Cairo:wght@400;500;600&display=swap");

:root {
  --yellow: #a58c3b;
  --black: #161616;
  --background: #ffffff;
  --grey: #7c7b7b;
  --blackText: #3f3f3f;
}
.mainWrapper {
  width: 84%;
  margin: auto;
}
.pagesWrapper{
  min-height: calc(100vh - 400px);
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  /* user-drag: none; */
}
.staticWhatsapp {
  position: fixed;
  z-index: 100000;
  bottom: 20px;
  right: 70px;
  transition: all 0.2s;
}
.staticWhatsapp:hover{
  cursor: pointer;
  transform: scale(1.1);
}
.staticWhatsapp{
  display: none;
}

body.dark-content .offersComponent .offerCard1{
  box-shadow: 0 0.5rem 2rem rgb(203 200 200 / 15%) !important;
}

body.white-content .offersComponent .offerCard1{
  box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 15%) !important;
}


body.white-content .offerCard1 .offerIcon img{
  filter: invert(1);
}

@media screen and (max-width:1800px){
  .homeWrapper *,.fourthSlide,.team,.offersPage{
    font-size:15px;
  }
}
@media screen and (max-width:1600px){
  .homeWrapper *,.fourthSlide,.team,.offersPage{
    font-size:14.5px;
  }
}
@media screen and (max-width:1500px){
  .homeWrapper *,.fourthSlide,.team,.offersPage{
    font-size:14px;
  }
}
@media screen and (max-width:1400px){
  .homeWrapper *,.fourthSlide,.team,.offersPage{
    font-size:13.5px;
  }
}
@media screen and (max-width:1300px){
  .homeWrapper *,.fourthSlide,.team,.offersPage{
    font-size:13px;
  }
}
@media screen and (max-width:1200px){
  .homeWrapper *,.fourthSlide,.team,.offersPage {
    font-size:12px;
  }
}
@media (max-width: 992px) {
  .mainWrapper {
    width: 90%;
  }
  .mobileWrapper {
    width: 78%;
  }
  .staticWhatsapp{
    right: unset;
    left: 26px;
    display: block;
  }
  .staticWhatsapp img{
    width: 58px;
    height: 58px;
  }
}

.white-content {
  background-color: var(--white);
}
.dark-content {
  background-color:  #161616;
}
.swiper-pagination-bullet{
  background-color: var(--mainText) !important;
}