/* @import url('../../images/404.png'); */
.notfoundImg {
  width: 545px;
  height: 372px;
  object-fit: contain;
}
.notfoundHeadline {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 66px;
  line-height: 82px;
  color: var(--mainText);
  margin-bottom: 30px;
}
.homeLink {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  /* identical to box height */
  text-decoration: none;
  color: var(--mainText);
  margin-top: 100px;
  margin-bottom: 30px;

}
.homeLink:hover{
  color: #3f3f3f;
}
@media screen and (max-width:992px) {
  .notFoundpage .notfoundImg {
  width: 300px;
    height: 272px;
}
.notFoundpage .notfoundHeadline{
  font-size:46px ;
}
.notFoundpage .fifthslidePar{
font-size: 1.3em;
}
  .homeLink{
    font-size: 30px;
  }
}
/* .bg{
    background:url('../../images/404.png')
} */
