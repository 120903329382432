.servicesEllipse {
  position: absolute;
  bottom: 0;
  right: -150px;
  z-index: -1;
}

.servicesContainer {
  column-gap: 100px;
}

.servicesNavtabs {
  background: var(--background);
  border: 2.50225px solid #a58c3b;
  box-sizing: border-box;
  border-radius: 4.17042px;
  padding: 10px;
  margin-bottom: 26px;
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  color: var(--mainText);
  width: 100%;
  transition: all 0.2s ;
  transition-timing-function: ease-in-out;
}

.servicesNavtabsLarge {
  font-size: 25px;
  height: 96px;
}

.servicesNavtabsLarge img {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

.servicesNavtabsSmall {
  font-size: 20px;
  height: 80px;
}

.servicesNavtabsSmall img {
  width: 30px;
  height: 30px;
}

.serviceTitle {
  margin-bottom: 60px;
}

.servicesNavtabs.active, .servicesNavtabs:hover{
  background-color: var(--yellow);
  color: var(--background);
}

.servicesNavtabs.active img ,.servicesNavtabs:hover img{
  filter: brightness(0) saturate(100%) invert(100%) sepia(18%) saturate(6073%) hue-rotate(244deg) brightness(123%) contrast(112%);
}

.servicesHeadline {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 76px;
  color: var(--mainText);
}

.servicesPar {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: var(--grey);
  margin-top: 100px;
}

.servicesContent p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--grey);
  /* margin-bottom: 50px; */
}

.servicesContent ul,
.mobileServices .collapsedService ul {
  width: 100%;
  list-style: none;
  padding-left: 0;
}

.servicesContent ul {
  display: flex;
  flex-wrap: wrap;
  padding-inline-start: 0;
  list-style: none;
}

.servicesContent ul li {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20.8621px;
  line-height: 26px;
  color: var(--mainText);
  margin-bottom: 50px;
  display: inline-block;
  width: 50%;
  /* display:inline-block; */
}

.servicesContent ul li::before,
.caseStudyWrapper ul li::before {
  content: "";
  background-color: var(--yellow);
  font-weight: bold;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-inline-end: 10px;
}
@media (max-width:1700px) {
  .servicesEllipe{
    right: -133px;
  }
}

@media (max-width:1600px) {
  .servicesHeadline {
    font-size: 60px;
  }

  .servicesPar {
    font-size: 20px;
    margin-top: 50px;
  }

  .servicesNavtabsLarge span{
    font-size: 20px;
  }

  .servicesContainer {
    column-gap: 150px;
  }

}

@media (max-width:1500px) {
  .servicesHeadline {
    font-size: 45px;
  }
}

@media (max-width:1400px) {
  .servicesEllipse {
    right: -114px;
  }
  .servicesNavtabsLarge span{
    font-size: 18px;
  }
}
@media (max-width:1100px) {
  .servicesContainer {
    column-gap: 83px;
}
.servicesNavtabsLarge span{
  font-size: 16px;
}
}
@media (max-width:1070px) {
  .servicesContainer {
    column-gap: 100px;
  }
}

@media (max-width: 992px) {
  .servicesNavtabs.active img ,.servicesNavtabs:hover img{
    filter:none;
  }
  .servicesNavtabs.active, .servicesNavtabs:hover{
    color: var(--mainText);
  }
  
  .mobileServices .servicesNavtabsLarge {
    font-size: 22px;
    height: auto;
  }
  .mobileServices .servicesNavtabsLarge span{
    white-space: nowrap;
  }
  .servicesNavtabs:hover{
    background-color: var(--background);
  }
  .servicesNavtabs {
    padding: 25px;
  }

  .servicesHeadline {
    font-size: 35px;
    margin-bottom: 50px;
  }

  .mobileServices .collapsedService {
    text-align: start;
  }

  .mobileServices .collapsedService p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: var(--grey);
  }

  .mobileServices .collapsedService ul li {
    font-family: "Kumbh Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    color: var(--mainText);
    margin-bottom: 30px;
  }

  .mobileServices .collapsedService ul li::before {
    content: "";
    background-color: var(--yellow);
    font-weight: bold;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-inline-end: 10px;
  }
}