.aboutusWrapper {
  margin-top: 100px;
}

.about-us-info {
  margin-top: 50px;
}

.about-us-info .homeWord {
  margin-bottom: 22px;
}

.about-us-img,
.visionImg {
  object-fit: cover;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
  border-radius: 6px;
}

.about-us-img {
  height: 531px;
}

.visionImg {
  height: 500px;
}

.splitter {
  width: 100%;
  text-align: center;
  border-top: 3px solid #282828;
  line-height: 0.1em;
  margin: 100px 0;
}

.splitter span {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  color: var(--mainText);
  background-color: var(--background);
  padding: 0 20px;
}

.about-quote {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: var(--grey);
  margin: 60px 0;
}



.aboutUs-Ellipse {
  position: absolute;
  top: -120px;
  z-index: -1;
  left: -151px;
}

.about-second {
  margin-top: 140px;
  margin-bottom: 140px;
}

.team {
  width: 100%;
  margin-bottom: 150px;
  height: 450px;
}

.team .swiper-slide {
  background-position: center;
  background-size: cover;
  height: 247px;
}

.team .swiper-slide-active {
  transform: scale(1.2) !important;
  margin-top: 50px;
}

.team .swiper-slide img {
  display: block;
  width: 16.875rem;
  height: 16.875rem;
  object-fit: cover;
  border-radius: 50%;
}

.team .swiper-slide h6 {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: var(--mainText);
  margin-top: 10px;
}

.team .swiper-slide span {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;

  text-align: center;
  color: var(--grey);
}

.team .swiper-slide {
  text-align: center;
  font-size: 18px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.teamSection {
  width: 75%;
  margin: auto;
  text-align: center;

}

.teamSection p {
  margin-top: 190px;
  margin-bottom: 100px;
}

.teamSection .homeWord {
  margin-top: 25px;
  margin-bottom: 60px;
}

.aboutusEllipse {
  position: absolute;
  bottom: -57px;
  right: 158px;
  z-index: -1;
}

.team .swiper-button-next,
.team .swiper-button-prev {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.team .swiper-button-next {
  background-image: url("../../images/rightArrow.svg");
}

.team .swiper-button-prev {
  background-image: url("../../images/leftArrow.svg");
}

.team .swiper-button-next::after,
.team .swiper-button-prev::after {
  display: none;
}

.growSection {
  width: 75%;
  margin: auto;
  margin-top: 300px;
  padding-bottom: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width:1400px) {
  .teamSection {
    width: 100%;
  }
}

@media (max-width:1024px) {
  .aboutusWrapper {
    margin-top: 50px;
  }

  .splitter span {
    font-size: 35px;
  }

  .servicesNavtabsLarge {
    width: 300px;
    height: 88px;
  }

  .about-quote {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin: 30px 0;
  }

  .about-second {
    margin-bottom: 100px;
  }

  .team {
    padding: 0px;
    margin-bottom: 100px;
  }

  .team .swiper-slide img {
    width: 172px;
    height: 172px;
  }

  .team .swiper-slide-active {
    transform: scale(1) !important;
    margin-top: 50px;
  }

  .growSection {
    margin-top: 88px;
    padding-bottom: 63px;
  }

  .aboutusWrapper .projectswiper .swiper-custom-button-next,
  .aboutusWrapper .projectswiper .swiper-custom-button-prev {
    display: none;
  }
}

@media screen and (max-width:992px) {
  .about-us-img {
    height: 260px;
  }

  .visionImg {
    height: 328px;
  }

}

@media screen and (max-width:767px) {

  .aboutusWrapper .projectswiper .swiper-custom-button-next,
  .aboutusWrapper .projectswiper .swiper-custom-button-prev {
    display: block;
    top: 30%;
    z-index: 10;
  }
}

@media screen and (max-width:600px) {
  .aboutUs-Ellipse {
    top: 970px;
    left: -178px;
    display: block !important;
  }

  .growSection {
    width: 97%;
  }
}

@media screen and (max-width:420px) {
  .aboutUs-Ellipse {
    top: 1000px;
    left: -266px;
  }

}

/* .about-second,.about-first {
  animation: fadeIn; 
  animation-duration: 2s; 
}
 */