.navbar {
  height: 90px;
  display: flex;
  justify-content: space-between;
}

.navbar-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links {
  margin-inline-end: 80px;
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #7c7b7b !important;
  text-decoration: none;
  padding: 10px;
}

.nav-links:hover {
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
}

.navbar-list .nav-links.active {
  color: var(--mainText) !important;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--yellow);
  border-radius: 0;
  background: none;

}

.mobile_links {
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 37px;
  color: var(--mainText);
  text-decoration: none;
  padding-bottom: 20px;
}

.mobile_links:hover {
  color: var(--yellow);
}

.navbarMobile-list {
  list-style: none;
  width: 80%;
}

.navbarMobile-list li {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 2px solid var(--yellow);
}

.themeIcons {
  margin-bottom: 10px;
}

.offcanvas {
  background-color: var(--background) !important;
}

.navbar-toggler {
  box-shadow: none;
  outline: none;
  border: none;
}

.btnClose {
  padding: 28px !important;
  border: none !important;
  background: none !important;

}

.btnClose svg path {
  fill: var(--mainText);
}

.navbar-toggler:is(:focus, :hover),
.btnClose:is(:focus, :hover) {
  box-shadow: none !important;
  outline: none;
  border: none;
}

@media screen and (max-width:1300px) {
  .nav-links {
    margin-inline-end: 65px;
  }
}

@media screen and (max-width:1200px) {
  .nav-links {
    margin-inline-end: 40px;

    font-size: 18px;

  }
}

@media (max-width:992px) {
  .navbar {
    height: 100px;
    position: sticky;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  }

  .navbarFooter .socialIcons,
  .themeIcons .socialIcons {
    padding-inline-end: 0 !important;
  }

  .navbarFooter {
    border-top: 2px solid #282828;
    padding-top: 30px;
  }

  .socailNavbar {
    width: 75%;
    margin: auto;
  }

  .footerFont {
    font-size: 15px !important;
  }

  .nav-links {
    margin-inline-end: 70px;
  }
}