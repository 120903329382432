.blog {
  padding: 30px 0;
  border-bottom: 2px solid var(--yellow);
}
.blogImg {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blogName {
  font-family: " Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 37px;
  color: var(--mainText);
}
.blogDate {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: var(--grey);
  width: 40%;
}
.blogDate span{
  text-transform: capitalize ;
}
.tagsWrapper {
  background: var(--background);
  border: 2px solid var(--yellow);
  box-sizing: border-box;
  box-shadow: inset 0px 5px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.badges {
  background: #e4e4e4;
  border-radius: 40px;
  padding: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: var(--grey);
  margin-inline-end: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.badges.active {
  color: var(--background);
  background-color: var(--yellow);
}
.archivesSection {
  margin-top: 60px;
  margin-bottom: 50px;
  padding-bottom: 40px;
  border-bottom: 2px solid #282828;
}
.archivesSection div {
  margin-bottom: 25px;
}
.archivesSection h5,
.posts h5 {
  color: var(--mainText);
  margin-bottom: 30px;
}
.archivesSection h5,
.posts h5,
.postName {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.3px;
}
.postName {
  color: var(--yellow);
}
.posts {
  margin-bottom: 50px;
}
/* Blog details page */
.blogTitle {
  width: 50%;
  margin-bottom: 50px;
}
.blogTitle h2 {
  font-family: " Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 81px;
  color: var(--mainText);
  margin-bottom: 20px;
}
.blogdata time,
.blogdata span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: var(--grey);
}

.blogmainImg {
  height: 435px;
  width: 100% !important;
  object-fit: cover;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.articleSection {
  margin-bottom: 100px;
}
.articleSection p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: var(--grey);
  margin-bottom: 50px;
}
.articlePagination {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}
.articlePagination button {
  padding: 15px 25px;
}
.archiveYear{
  color: var(--mainText);
  cursor: pointer;
}
.noResults{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  height:100vh;
  color:var(--grey);
  font-size: 50px;
  font-family: " Kumbh Sans", sans-serif;
}
@media (max-width: 1305px) {
  .blogTitle {
    width: 70%;
  }
}
@media (max-width: 992px) {
  .blogTitle {
    width: 100%;
  }
  .blogTitle h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .blogdata time,
  .blogdata span {
    font-size: 15px;
    line-height: 18px;
  }
  .articleSection{
    margin-bottom: 10px;
  }
  .articlePagination button {
    margin-top: 0;
  }
  .articlePagination{
    width: 100%;
  }
  .articleSection p {
    font-size: 20px;
    line-height: 24px;
  }
  .blog-card {
    border: 3px solid var(--yellow);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    margin-top: 50px !important;
  }
  .blogDate {
    width: 100%;
  }
  .blogName {
    font-size: 23px;
    line-height: 29px;
    margin-top: 10px;
  }
  .blogSample {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: var(--grey);
    margin-top: 50px;
  }
  .categoriesBtn.show {
    background-color: var(--background);
    color: var(--grey);
    border: 1px solid var(--yellow);
  }
  .categoriesBtn.show svg path {
    fill: var(--yellow);
  }
  .categoriesBtnWrap .dropdown-menu {
    padding: 10px;
    border: 1px solid var(--yellow);
  }
  .categoriesBtnWrap .dropdown-menu .dropdown-item {
    width: 50%;
    margin: auto;
    text-align: center;
    color: var(--grey);
    border-bottom: 2px solid var(--grey);
    padding: 0.25rem 0;
  }
}
