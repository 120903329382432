.projectsWrapper{
  min-height: calc(100vh - 200px);
}
.allProjects nav .filterSwiper {
  list-style: none;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  height: 53px;
}
/* .allProjects nav .filterSwiper .swiper-slide{
  width:170px !important;
} */
.allProjects nav .filterSwiper .swiper-slide span {
  /* margin-inline-end: 30px; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--grey);
  white-space: nowrap;
  cursor: pointer;
}
.allProjects nav .filterSwiper .swiper-slide span:hover {
  color: var(--mainText);
}
.filterSwiper {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: center;
}
.allProjects .filterSwiper .swiper-slide span.activeProject {
  color: var(--mainText);
  border-bottom: 3px solid #a58c3b;
  padding-bottom: 10px;
}
.cardsImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.projectTitle {
  position: absolute;
  width: 100%;
  height: 76px;
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  color: #fff;
  /* font-family: "Inter", sans-serif; */
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  opacity: 0;
  border-radius: 0 0 5px 5px;
}
.cards:hover .projectTitle {
  opacity: 1;
}
.paginationBtn {
  display: flex;
  justify-content: center;
}
.allprojetcs {
  width: 87%;
  margin-bottom: 3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.fullEllipse {
  position: absolute;
  top: 15rem;
  right: 13rem;
  z-index: -1;
  height: 42.375rem;
}
.casestudyModal .modal-body {
  padding: 0;
}

/* *******case Study*********** */

.modalHeader {
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
}
.headerContent {
  padding: 120px;
}
.headerSubheading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  margin-top: 40px;
  color: var(--background);
}
.firstLine,
.secondLine,
.thirdLine {
  height: 5px;
  border-radius: 10px;
  background-color: var(--background);
}
.firstLine {
  width: 238px;
}
.secondLine {
  width: 103px;
  margin-right: 3px;
}
.thirdLine {
  width: 211px;
}
.projSummary {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  margin-top: 50px;
  color: var(--background);
  width: 50%;
}
.fields{width: 70%;}
.fields span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: var(--background);
  margin-inline-end: 50px;
}
.gradientSplitter {
  background: linear-gradient(
    95.37deg,
    #a58c3b -17.53%,
    #fce69e 23.78%,
    #a58c3b 73.25%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 62px;
  margin: 100px 0;
}

.gradientSplitter:before,
.gradientSplitter:after {
  content: "";
  border-top: 3px solid #282828;
  flex: 1 0 10px;
}
.caseStudyWrapper {
  width: 80%;
  margin: auto;
}
.caseStudyWrapper h2 {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: var(--blackText);
  margin-bottom: 40px;
}
.caseStudyWrapper ul {
  list-style: none;
}
.caseStudyWrapper ul li {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  color: #7c7b7b;
  margin-bottom: 20px;
}
.problems {
  margin-bottom: 150px;
}
.caseStudyWrapper p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  color: var(--grey);
  text-align: justify;
}
.caseStudyWrapper .rightimg img {
  object-fit: contain;
  border-radius: 5px;
  float: right;
  width: 100%;
}
.rightimg {
  margin-left: 10px;
  float: right;
  width: 40%;
}
.leftimg {
  margin-right: 25px;
  float: left;
  width: 40%;
}
.caseStudyWrapper .leftimg img {
  object-fit: contain;
  border-radius: 5px;
  float: left;
  width: 100%;
}
.results {
  background-color: var(--yellow);
  width: 50%;
  height: 80px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  font-family: "Inter", sans-serif;
  color: var(--background);
}
.caseStudyFooter {
  background-color: var(--yellow);
}
.BrandName {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 80px;
}
.BrandName h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 100px;
  line-height: 121px;
  color: var(--background);
  margin-bottom: 10px;
}
.BrandName h6 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: var(--background);
}
.logoFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
}
.footerimg {
  width: 143px;
  height: 143px;
  object-fit: contain;
}
.footerField {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: right;
  letter-spacing: 0.3em;
  color: var(--background);
}
@media (max-width: 1300px) {
  .allProjects{
    width: 100%;
  }
}
@media (max-width: 992px) {
  .headerContent {
    padding: 50px;
  }
  .headerContent img {
    width: 189px;
    height: 179px;
    object-fit: contain;
  }
  .headerSubheading {
    font-size: 16px;
  }
  .firstLine,
  .secondLine,
  .thirdLine {
    height: 3px;
  }
  .projSummary {
    width: 100%;
    font-size: 16px;
  }
  .fields{width:100%;}
  .fields span {
    font-size: 15px;
  margin-inline-end: 2em;
  }
  .gradientSplitter{
    font-size: 30px;
  }
  .rightimg {
    width:100%;
    float:none;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .caseStudyWrapper .rightimg img{
    margin-bottom: 20px;
  }
  .caseStudyWrapper p {
    font-size: 17px;
  }
  .caseStudyWrapper ul {
    padding-left: 0;
  }
  .caseStudyWrapper ul li {
    font-size: 16px;
  }
  .leftimg {
    width:100%;
    float:none;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .caseStudyWrapper .rightimg img{
    margin-bottom: 20px;
  }
  .results{
    width: 100%;
    font-size: 17px;
  }
  .BrandName h2{
    font-size: 55px;
  }
  .BrandName h6{
    font-size: 16px;
  }
  .footerimg {
    width:93px;
    height:93px;
  }
  .footerField{
    font-size: 30px;
  }

}
@media screen and (max-width:774px) {
  .allprojetcs{
    width: 100%;
    height: 290px;
  }
  .projectsWrapper{
    min-height: fit-content;
  }
  
}